import { ITableFiltersObject } from "@monorepo/utils/src/store/types/tableFiltersObject";
import { convertAutocompleteItemToApi } from "@monorepo/utils/src/api/convertFiltersToApi";

export const convertFiltersCustomToApi = (
  resultFilters: ITableFiltersObject & { fieldFilters: { creationDateFrom?: string; creationDateTo?: string; status?: string } }
): void => {
  convertAutocompleteItemToApi(resultFilters, "email", `fieldFilters.email.email`);
  convertAutocompleteItemToApi(resultFilters, "fullName", `fieldFilters.fullName.fullName`);
  convertAutocompleteItemToApi(resultFilters, "username", `fieldFilters.username.username`);
};
