import { IChapter, IRole, IRoleAPI } from "@monorepo/informationSecurity/src/views/SystemAccesses/types/IRole";
import { formAuthorities } from "@monorepo/informationSecurity/src/views/SystemAccesses/utils/formAuthorities";
import { IAuthorityGroup } from "@monorepo/authorization/src/views/Login/types/authResponse";

export const formRoles = (
  rolesList: IRoleAPI[],
  authoritiesGroups: IAuthorityGroup[]
): {
  data: IRole[];
  total: number;
} => {
  return (
    rolesList?.reduce(
      (result: { data: IRole[]; total: number }, role) => {
        const roleAuthorities = role.roleAuthorities
          .filter((item) => !!item)
          .reduce((result: { [key: string]: boolean }, item) => {
            result[item.name] = true;
            return result;
          }, {});
        const authorities = formAuthorities(authoritiesGroups, roleAuthorities);
        result.data.push({
          id: role.id,
          code: role.code,
          name: role.label || "",
          users: role.userCount ?? 0,
          chapters: authorities as unknown as IChapter[],
        });
        return result;
      },
      {
        data: [],
        total: rolesList.length,
      }
    ) || []
  );
};
