




























import SystemAccessesUserInfoModal from "@monorepo/informationSecurity/src/views/SystemAccesses/components/users/SystemAccessesUserInfoModal.vue";
import Breadcrumbs from "@monorepo/uikit/src/components/common/Breadcrumbs.vue";
import Tabs from "@monorepo/informationSecurity/src/views/SystemAccesses/components/Tabs.vue";
import { defineComponent } from "@vue/composition-api";
import { module as SystemAccessesView } from "@monorepo/informationSecurity/src/store/modules/systemAccessesView";
import useInitTableStoreModule from "@monorepo/utils/src/store/composables/useInitTableStoreModule";
import { breadcrumbs } from "@monorepo/informationSecurity/src/views/SystemAccesses/constants/breadcrumbs";
import { viewTitle } from "@monorepo/utils/src/variables/projectsData/systemAccesses/viewTitle";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import { mapGetters, Module } from "vuex";
import useGetTableLibs from "@monorepo/informationSecurity/src/views/SystemAccesses/composables/useGetTableLibs";

export default defineComponent({
  name: "SystemAccessesView",
  components: {
    Breadcrumbs,
    SystemAccessesUserInfoModal,
    Tabs,
    roles: () =>
      import(
        /* webpackChunkName: "SystemAccessesRolesTab" */
        "@monorepo/informationSecurity/src/views/SystemAccesses/components/roles/Roles.vue"
      ),
    users: () =>
      import(
        /* webpackChunkName: "SystemAccessesUsersTab" */
        "@monorepo/informationSecurity/src/views/SystemAccesses/components/users/Users.vue"
      ),
  },
  props: {
    roleId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tab: null,
      breadcrumbs,
      viewTitle,
      ModalType,
      showModal: false,
      type: ModalType.NEW,
    };
  },
  computed: {
    ...mapGetters("auth", ["isOpenEducation"]),
    ...mapGetters("systemAccessesView", ["isTableLoading"]),
    ...mapGetters("systemAccessesView", { openedId: "openedUserId" }),
    isShowModal() {
      return !this.isOpenEducation && Boolean(this.openedId);
    },
  },
  setup(props, { root }) {
    useInitTableStoreModule(root, "systemAccessesView", SystemAccessesView as Module<unknown, unknown>);
    useGetTableLibs(root);
  },
});
