import { ComponentInstance, onBeforeMount } from "@vue/composition-api";

const useGetTableLibs = (root: ComponentInstance): void => {
  const store = root.$store;

  onBeforeMount(() => {
    store.dispatch("systemAccessesView/getDefaultTwoStepsAuth");
    store.dispatch("systemAccessesView/getOiks");
    store.dispatch("systemAccessesView/getArchiveHierarchyLib");
  });
};

export default useGetTableLibs;
