import Inputmask from "inputmask";

export const convertSaveCardObject = (data: { [key: string]: unknown }): { [key: string]: unknown } => {
  return {
    ...(data.id ? { id: data.id } : {}),
    ...(data.password ? { password: data.password } : {}),
    enable2FA: data.enable2FA || false,
    username: data.username || "",
    email: data.email || "",
    fullName: data.fullName || "",
    vipnetCertId: data.vipnetCertId || null,
    phone: data.phone
      ? Inputmask.unmask(data.phone as string, {
          mask: "9 (999) 999-99-99",
        })
      : "",
    department: data.department || "",
    position: data.position || "",
    roles: data.roles || [],
    active: data.active || true,
    deleted: data.deleted || false,
  };
};
