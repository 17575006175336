



















































import { defineComponent, PropType } from "@vue/composition-api";
import { IArchiveElement } from "@monorepo/catalog/src/views/ArchiveView/types/archiveElement";
import { IFundElement } from "@monorepo/catalog/src/views/FundView/types/fundElement";
import Vue from "vue";
import { ModalType } from "@monorepo/utils/src/variables/modalType";
import SelectRolesCard from "@monorepo/uikit/src/components/common/Select/SelectRolesCard.vue";
import { IOikCardElement } from "@monorepo/catalog/src/views/OikView/types/oikCardElement";

interface ArchiveTreeItem extends IArchiveElement {
  funds: IFundElement[];
}

export default defineComponent({
  name: "AccessesTree",
  model: {
    prop: "accessValues",
    event: "change",
  },
  components: {
    SelectRolesCard,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    accessValues: {
      type: Object,
      default: () => ({ fundIds: {}, archiveIds: {}, oikIds: [] }),
    },
    type: {
      type: String,
    },
    infiniteId: {
      type: String,
    },
    oiks: {
      type: Array as PropType<IOikCardElement[]>,
      default: () => [] as IOikCardElement[],
    },
    getArchiveHierarchy: {
      type: Function,
      required: true,
    },
  },
  methods: {
    changeArchive(id: string, value: boolean) {
      this.treeItems
        .find((item) => item.id === id)
        ?.funds?.forEach((fund) => {
          Vue.set(this.accessValues.fundIds, fund.id, value);
        });
      this.updateArchives();
      this.updateOiks();
    },
    changeFund() {
      this.updateArchives();
      this.updateOiks();
    },
    updateOiks() {
      const oiksWithoutFunds = (this.accessValues.oikIds || [])
        .filter((oik: IOikCardElement) => !oik.funds?.length)
        .map((oik: IOikCardElement) => oik.id);
      const newOiks = this.oiks.filter(
        (oik: IOikCardElement) =>
          oiksWithoutFunds.includes(oik.id) || (oik.funds as { id: number }[]).some((fund) => this.accessValues.fundIds[fund.id])
      );
      Vue.set(this.accessValues, "oikIds", newOiks);
    },
    updateArchives() {
      const archivesWithoutFunds = this.treeItems
        .filter((archive: IArchiveElement) => !archive.funds?.length && this.accessValues.archiveIds[archive.id])
        .map((archive: IArchiveElement) => archive.id);
      const newArchives = this.treeItems
        .filter(
          (archive: IArchiveElement) =>
            archivesWithoutFunds.includes(archive.id) ||
            (archive.funds?.length && archive.funds.every((fund: IFundElement) => this.accessValues.fundIds[fund.id]))
        )
        .map((archive: IArchiveElement) => archive.id);
      const additionalArchives = this.treeItems
        .filter(
          (archive: IArchiveElement) =>
            !newArchives.includes(archive.id) &&
            archive.funds?.length &&
            archive.funds.some((fund: IFundElement) => this.accessValues.fundIds[fund.id])
        )
        .map((archive: IArchiveElement) => archive.id);
      const result = newArchives.reduce((acc: Record<string, boolean>, curr: string) => {
        acc[curr] = true;
        return acc;
      }, {});
      const additionalArchivesResult = additionalArchives.reduce((acc: Record<string, boolean>, curr: string) => {
        acc[curr] = true;
        return acc;
      }, {});
      Vue.set(this.accessValues, "archiveIds", result);
      Vue.set(this.accessValues, "additionalArchiveIds", additionalArchivesResult);
    },
    onChangeOik(values: IOikCardElement[]) {
      let newFundIds = [] as (string | number)[];
      values.forEach((oik: IOikCardElement) => {
        newFundIds = newFundIds.concat((oik.funds as { id: number }[]).map((item) => item.id));
      });
      const result = newFundIds.reduce((acc: Record<string, boolean>, curr: string | number) => {
        acc[curr] = true;
        return acc;
      }, {});
      Vue.set(this.accessValues, "fundIds", result);
      this.updateArchives();
    },
    getTreeItems() {
      (this.getArchiveHierarchy as unknown as () => Promise<ArchiveTreeItem[]>)().then((result: ArchiveTreeItem[]) => {
        if (result) {
          this.treeItems = (result || []).reduce((result: ArchiveTreeItem[], item: ArchiveTreeItem) => {
            if (!item.isDeleted) {
              const resultItem = {
                ...item,
                funds: item.funds
                  .filter((fond) => !fond.isDeleted)
                  .sort((a, b) => (a.name || "").localeCompare(b.name || ""))
                  .map((fond) => ({ ...fond, parentId: item.id })),
              };
              result = result.concat(resultItem);
            }
            return result;
          }, []);
          this.updateArchives();
        }
      });
    },
  },
  data() {
    return {
      treeItems: [] as ArchiveTreeItem[],
      ModalType,
    };
  },
  watch: {
    infiniteId: {
      immediate: true,
      handler() {
        this.getTreeItems();
      },
    },
  },
});
