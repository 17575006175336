import { SORT_TYPE } from "@monorepo/utils/src/types/sortTypes";

export const sortTableOnlyDateWithNonDate = (
  sortObject: Record<string, SORT_TYPE>,
  newFieldSort: Record<string, SORT_TYPE>,
  dateFields: string[]
): Record<string, SORT_TYPE> => {
  const dateFieldsObject: Record<string, true> = dateFields.reduce((result: Record<string, true>, value) => {
    result[value] = true;
    return result;
  }, {});
  const isNewSortByDate = Object.keys(newFieldSort).some((element) => dateFieldsObject[element]);
  // 1 мы отсоритровали по дате
  // если по дате то мы ее исключаем
  // 2 мы отсортировали не по дате
  // если 2 то мы исключаем поля которые без даты
  const filterOldObject = Object.entries(sortObject).reduce((result: Record<string, SORT_TYPE>, [key, value]) => {
    if (isNewSortByDate) {
      if (!dateFieldsObject[key]) {
        result[key] = value;
      }

      return result;
    }

    if (dateFieldsObject[key]) {
      result[key] = value;
      return result;
    }
    return result;
  }, {});

  return {
    ...filterOldObject,
    ...newFieldSort,
  };
};
