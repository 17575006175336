import { IAuthority } from "@monorepo/authorization/src/views/Login/types/authResponse";

export const CREATE_SUB_KEY = "CREATE";
export const EDIT_SUB_KEY = "MODIFY";
export const DELETE_SUB_KEY = "DELETE";
export const VIEW_SUB_KEY = "LIST";
export const EXPORT_SUB_KEY = "EXPORT";
export const AUTHORITY_SUB_KEY = "_authority";
export const CREATE_OBJECT_KEY = "isCreate";
export const EDIT_OBJECT_KEY = "isEdit";
export const DELETE_OBJECT_KEY = "isDelete";
export const VIEW_OBJECT_KEY = "isViewing";
export const EXPORT_OBJECT_KEY = "isExport";

type result = {
  [x: string]: string | boolean | IAuthority | null;
  id: string;
  title: string;
  isViewing: null;
  isCreate: null;
  isEdit: boolean;
  isDelete: null;
  isExport: null;
};

const formElement = ({
  key,
  subKey,
  activeElements,
  authority,
}: {
  key: string;
  subKey: string;
  activeElements: Record<string, boolean>;
  authority: IAuthority;
}) => {
  const result: Record<string, IAuthority | boolean> = {};
  if (authority.name.endsWith(subKey)) {
    result[key] = activeElements[authority.name] || false;
    result[`${key}${AUTHORITY_SUB_KEY}`] = authority;
  }
  return result;
};

export const formAuthorities = (
  authorities: { code: number; label: string; authorities: IAuthority[] }[],
  activeElements: { [key: string]: boolean }
): (result | { id: number; title: string })[] => {
  return (authorities || [])
    .reduce((result: (result | { id: number; title: string })[], element) => {
      const exceptions: IAuthority[] = [];
      const object = {
        id: element.code,
        title: element.label,
        ...(element.authorities || []).reduce(
          (result: { [key: string]: boolean | null | IAuthority }, authority) => {
            const viewingResult = formElement({ key: VIEW_OBJECT_KEY, subKey: VIEW_SUB_KEY, activeElements, authority });
            const editResult = formElement({ key: CREATE_OBJECT_KEY, subKey: CREATE_SUB_KEY, activeElements, authority });
            const createResult = formElement({ key: EDIT_OBJECT_KEY, subKey: EDIT_SUB_KEY, activeElements, authority });
            const deleteResult = formElement({ key: DELETE_OBJECT_KEY, subKey: DELETE_SUB_KEY, activeElements, authority });
            const exportResult = formElement({ key: EXPORT_OBJECT_KEY, subKey: EXPORT_SUB_KEY, activeElements, authority });
            if ([viewingResult, editResult, createResult, deleteResult, exportResult].every((object) => !Object.keys(object).length)) {
              exceptions.push(authority);
            }
            return { ...result, ...viewingResult, ...editResult, ...createResult, ...deleteResult, ...exportResult };
          },
          {
            [VIEW_OBJECT_KEY]: null,
            [CREATE_OBJECT_KEY]: null,
            [EDIT_OBJECT_KEY]: null,
            [DELETE_OBJECT_KEY]: null,
            [EXPORT_OBJECT_KEY]: null,
          }
        ),
      };
      const exceptionsObject = exceptions.map((authority: IAuthority) => {
        return {
          id: `exceptions${authority.code}`,
          title: authority.title,
          [VIEW_OBJECT_KEY]: null,
          [CREATE_OBJECT_KEY]: null,
          [EDIT_OBJECT_KEY]: activeElements[authority.name] || false,
          [`${EDIT_OBJECT_KEY}${AUTHORITY_SUB_KEY}`]: authority,
          [DELETE_OBJECT_KEY]: null,
          [EXPORT_OBJECT_KEY]: null,
        };
      });
      return [...result, object, ...exceptionsObject];
    }, [])
    .filter((item: Record<string, unknown>) =>
      [VIEW_OBJECT_KEY, CREATE_OBJECT_KEY, EDIT_OBJECT_KEY, DELETE_OBJECT_KEY, EXPORT_OBJECT_KEY].some((key) => item[key] !== null)
    );
};
